export const bannerType=[{
    key:"Mobile",
    value:'1'
},
{
    key:"DeskTop",
    value:"2"
}]

export const topEnum='1'

export const showBannerEnum=[{
    key:"Top",
    value:'1'
},
{
    key:"Middle",
    value:'2'
},
{
    key:"Lower",
    value:'3' 
}]

export const paymentStatus={
    Verified:"Verified",
    Pending:'Pending',
}